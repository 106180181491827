import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";
import MenuBar from "../components/MenuBar";
import InvestmentCard from "../components/InvestmentCard";

import "./../styles/index.scss";
import { getImage, ImageDataLike } from "gatsby-plugin-image";
import HeroBanner from "../components/HeroBanner";
import Footer from "../components/Footer";
import PageContent from "../components/PageContent";
import { PortfolioPageQuery } from "../../graphql-types";
import { mustBeDefined } from "../utils";
import ReactMarkdown from "react-markdown";
import InvestmentSection from "../components/InvestmentsSection/investmentsSection";

const PortfolioPage: React.FC = () => {
  const queryResults = useStaticQuery<PortfolioPageQuery>(query);

  type Categories = Object & {
    [key: string]: { name: string, cleanName: string, state: boolean };
  }

  let investments = mustBeDefined(queryResults.investments).otherwise(
    "CMS_DATA_MISSING",
    "PortfolioPageQuery.investments"
  );

  investments = investments.edges.map((edge) => edge.node);
  const uniqueCategories = queryResults.investments.uniqueCategories;
  const allCategories: Categories = {
    "All": { name: "All", cleanName: "All Investments", state: true }
  };
  
  uniqueCategories.map((uniqueCategory: string) => Object.assign(allCategories, {
    [uniqueCategory]: {
      name: uniqueCategory,
      cleanName: uniqueCategory.replace(/_/g, ' '),
      state: false
    }
  }));

  const sorter = (a: { name: string }, b: { name: string }) => a.name.localeCompare(b.name);
  const exitedInvestments = investments.filter(
    (investment) => investment.exited
  ).sort(sorter);

  const activeInvestments = investments.filter(
    (investment) => !investment.exited
  ).sort(sorter);

  const sections = mustBeDefined(queryResults.sections).otherwise(
    "CMS_DATA_MISSING",
    "PortfolioPageQuery.sections"
  );

  const portfolioBannerImageDetails = mustBeDefined(
    queryResults.portfolioBanner
  ).otherwise("IMAGE_DATA_MISSING", "PortfolioPageQuery.portfolioBanner");


  const portfolioBannerImage = getImage(
    portfolioBannerImageDetails as ImageDataLike
  );

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="BAM Elevate Investments Portfolio" />
        <title>BAM Elevate - Portfolio</title>
      </Helmet>
      <main className="w-screen bg-off-white">
        <MenuBar />

        <HeroBanner image={portfolioBannerImage!} title={sections.heroTitle?.data?.childMarkdownRemark?.rawMarkdownBody} description={sections.heroDescription?.data?.childMarkdownRemark?.rawMarkdownBody} objectPosition="center" objectPositionMobile="left" />

        <InvestmentSection allCategories={allCategories} investments={activeInvestments} title={sections.activePortfolioTitle} />
        {/* <InvestmentSection allCategories={allCategories} investments={exitedInvestments} title={sections.exitsTitle} /> */}

        <PageContent>
          <ReactMarkdown children={sections.disclaimer?.data?.childMarkdownRemark?.rawMarkdownBody} className="w-[90%] md:w-1/2 text-xxs xl:text-xs" />
        </PageContent>

        <PageContent>
          <Footer />
        </PageContent>
      </main>
    </>
  );
};

export default PortfolioPage;

export const query = graphql`
  query PortfolioPage {
    sections: strapiPortfolioPage {
      heroTitle {
        data {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
      }
      heroDescription {
        data {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
      }
      activePortfolioTitle
      exitsTitle
      disclaimer {
        data {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
      }
    }
    investments: allStrapiInvestment(filter: { category: { ne: null }, icon: { name: { ne: null } }, hidden: { ne: true} }) {
      edges {
        node {
          name
          website
          exited
          category
          icon {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 90, width: 300)
              }
            }
          }
        }
      }
      uniqueCategories: distinct(field: category)
    }
    portfolioBanner: file(
      relativePath: { eq: "header_images/img_portfolio_main.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 90, layout: FULL_WIDTH)
      }
    }
  }
`;
