import React, {useState} from "react";
import InvestmentCard from "../InvestmentCard";
import PageContent from "../PageContent";

type Categories = Object & {
    [key: string]: { name: string, cleanName: string, state: boolean };
  }

interface InvestmentSectionProps {
    allCategories: Categories,
    investments: any,
    title: string
}  


const InvestmentSection: React.FC<InvestmentSectionProps> = ({allCategories, investments, title}) => {
    const [currentCategory, setCategory] = useState(allCategories["All"]);
    const [showDropdown, setShowDropdown] = useState(false);

    const updateCategory = (category: any) => {
        setCategory(category);
        setShowDropdown(false);
      }
    

    return (
        <PageContent>
          <div>
            <div className="pb-5 border-b border-b-orange flex flex-col md:flex-row">
              <div className="font-title-mobile md:font-title text-section-title-mobile md:text-2xl text-blue text-center md:text-black md:text-start w-full pb-5">
                {title}
              </div>
              <div className="relative flex items-center cursor-pointer justify-end md:justify-start" tabIndex={0} onBlur={() => setShowDropdown(false)}>
                <div className="md:min-w-[250px]" onClick={() => setShowDropdown(!showDropdown)}>
                  <div className="flex bg-off-white text-sm md:text-base items-center px-4 jus">
                    <div className="grow h-0"></div>
                    {currentCategory.cleanName}
                    <svg className="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="gray" aria-hidden="true">
                      <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                    </svg>
                  </div>
                </div>

                <div className={`absolute top-[30px] md:top-[60px] md:min-w-[250px] z-50 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none ${showDropdown ? "block" : "hidden"}`}>
                  <div className="py-1">
                    {
                      Object.values(allCategories).map((category, i) => {
                        return (
                          category.cleanName !== currentCategory.cleanName && <div className="block px-4 py-2 cursor-pointer hover:bg-off-white text-sm md:text-base" key={i} onClick={() => updateCategory(category)}>{category.cleanName}</div>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
              {
                investments.map((item, i) => {
                  return (
                    (item.category === currentCategory.name || currentCategory.cleanName === "All Investments") &&
                    <InvestmentCard key={item.name || i} {...item} icon={item.icon as any} />
                  )
                })
              }
            </div>
          </div>
        </PageContent>
    )
}

export default InvestmentSection;